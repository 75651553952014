.top {
    width:100%;
    height: 200px;
    background-color: white;
    top: 0;
    position: fixed;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Josefin Sans', sans-serif;
}

.topIcon {
    width: 100px;
    height: 100px;
    object-fit: cover;
    padding: 20px;
}

.menu {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
}

.menuItem {
    margin-right: 30px;
    font-size: 20px;
    font-weight: 300;
    cursor: pointer;
    :hover {
        font-weight: 500;
    }
}


