.sidebar {
    flex: 3;
    margin: 20px;
    padding-bottom: 30px;
    background-color: #fdfbfb;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Varela Round', sans-serif;
}

.sidebarItem {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebarTitle {
    margin: 10px;
    padding: 5px;
    width: 80%;
    border-top: 1px solid #a7a4a4;
    border-bottom: 1px solid #a7a4a4;
    font-family: 'Varela Round', sans-serif;
    font-size: 12px;
    color: #222;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
}

img {
    margin-top: 15px;
    margin-bottom: 10px;
    width: 200px;
    height: 200px;
    object-fit: cover;
}

p {
    font-family: 'Josefin Sans', sans-serif;
    margin: 20px 50px;
    font-size: 14px;
}

.sidebarList {
    list-style: none;
    margin-bottom: 30px;
}

.sidebarListItem {
    display: inline-block;
    width: 50%;
    margin-top: 15px;
}