.projectList {
    flex: 9;
}

.projects {
    display: flex;
    flex-wrap: wrap;
    margin: 25px;
    justify-content: center;
}

.leadTitle {
    color: #606060;
    padding: 10px 60px;
    font-family: 'Josefin Sans', sans-serif;
}

.project {
    width: 385px;
    margin: 0px 30px 40px 30px;
}

.projectImage {
    width: 100%;
    height: 280px;
    object-fit: cover;
    border-radius: 7px;
}

.projectInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.projectCat {
    font-family: 'Varela Round', sans-serif;
    font-size: 11px;
    color: gray;
    line-height: 25px;
    margin-top: 15px;
    margin-right: 10px;
}

.projectTitle {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 24px;
    font-weight: 700;
    margin-top: 15px;
    cursor: pointer;
}

a {
    text-decoration: none;
    color: #606060;
}