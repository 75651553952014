.previewBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sheetTitle {
    width: 100%;
    color: #606060;
    font-family: 'Josefin Sans', sans-serif;
}

thead {
    margin: 15px;
}

.container {
    display: flex;
    flex-direction: column;
}

.grid {
    border: none;
    width: 400px;
    height: 400px;
    font-weight: 300;
    margin: 30px;
}

.individual {
    font-size: 20px;
}

.targetContainer {
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
}

.targetItem {
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 18px;
    width: 20%;
    text-align: center;

}

@media print {
    .targetItem {
        width: 150px;
    }

    .grid {
        width: 600px;
        height: 600px;
    }
    .individual {
        font-size: 30px;
        width: 60px;
        height: 60px;
    }
    .targetItem {
        font-size: 20px;
    }
}