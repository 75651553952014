
.inputChar {
    width: 100px;
    height: 30px;
    margin: 5px;
    font-size: 20px;
}

.inputBox {
    width: 80%;
    height: 30px;
    margin: 5px;
    font-size: 20px;
}

.card {
    margin-top: 60px;
    margin-bottom: 60px;
}

.individual {
    text-align: center;
    font-size: 25px;
}

table{
    border: 1px solid black;
    border-collapse: collapse;
    width: 300px;
    height: 300px;
}

@media print {
    .pagebreak { page-break-before: always; } 

    .card {
        padding-top: 200px;
        width: 100%;
    }
    thead {
        position: fixed;
        font-size: 100px;
        top: 30px;
    }
    table {
        width: 500px;
        height: 500px;
    }
    .individual {
        font-size: 45px;
    }
    
}




